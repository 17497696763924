export function getConfig(p = {}) {

    const { config = {} } = p

    const commonConfig = config.common || {}
    const globalsConfig = config.globals || {}

    const { WAPP, DEV } = globalsConfig

    const common = {
        ...commonConfig,
        lang: 'hu_HU',
        siteName: 'Könyv és Játék',
        description: 'Üdvözlünk a Könyv és Játék webáruházban! Nyitási akció! Folyamatos kedvezménnyekkel vásárolhatsz nálunk! Gyere és merülj el velünk a mesekönyvek és a játékok világában! Küldetésünk, hogy támogassuk a szülőket a tökéletes könyvek és játékok kiválasztásában, legyen szó születésnapról, névnapról vagy karácsonyról. A legkedveltebb kiadók már velünk vannak, mint a Scolar és a Pagony, de kínálatunk folyamatosan frissül...',
        graphql: {
            route: (DEV) ? '/graphql' : '/g' + WAPP
        }
    }

    return {
        config: {
            ...config,
            common: common
        }
    }
}
