import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'user'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,

            ['new' + N + 'SubmitLabel']: 'Mentés',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Felhasználók törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Adminnak jelölés',
            ['removeProtection' + N + 'Text']: 'Admin jog elvétele',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',

            [ns + 'SortRelevance']: 'Relevancia',

            loginSubmitLabel: 'Belépés',
            logoutSubmitLabel: (s) => s > 0 ? 'Kilépés ' + s + ' másodperc múlva...' : 'Kilépés',
            signupSubmitLabel: 'Regisztráció',
            forgotPasswordSubmitLabel: 'Visszaállító e-mail küldése',
            resetPasswordSubmitLabel: 'Jelszó visszaállítása',
            changeEmailSubmitLabel: 'E-mail cím megváltoztatása',
            changePasswordSubmitLabel: 'Jelszó megváltozatása',
            emailConfirmationSubmitLabel: 'E-mail cím megerősítése',
            deleteAccountLabel: 'Fiók törlése',
            emailConfirmationSendAgainSubmitLabel: 'Megerősítő e-mail újraküldése',
            acceptTermsLabel: 'ÁSZF elfogadása',
            acceptPrivacyLabel: 'Adatvédelem elfogadása',
            termsWord: 'ÁSZF',
            privacyWord: 'Adatvédelem',
            signupQuestion: 'Nincs még profilod?',
            nextToSignup: 'Tovább a regisztrációhoz!',
            loginQuestion: 'Már van profilod?',
            nextToLogin: 'Tovább a belépéshez!',

            [n + 'Overview']: 'Áttekintés',
            [n + 'OverviewAboutOrders']: 'Rendelések',
            [n + 'OverviewUnpaidOrders']: 'Nincs fizetve',
            [n + 'OverviewProcessingOrders']: 'Folyamatban',
            [n + 'OverviewSuccessOrders']: 'Teljesült',

            [n + 'OverviewForAdmin']: 'Admin'

        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Felhasználók',
            ['my' + Ns + 'Menu']: 'Felhasználóim',
            ['new' + N + 'Menu']: 'Új felhasználó',
            [ns + 'Menu']: 'Felhasználók',
            ['deleted' + Ns + 'Menu']: 'Törölt felhasználók',
            ['banned' + Ns + 'Menu']: 'Tiltott felhasználók',
            ['protection' + Ns + 'Menu']: 'Adminok',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: '',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Adminnak jelölés',
            ['removeProtection' + N + 'Menu']: 'Admin jog törlése',

            loginMenu: 'Belépés',
            signupMenu: 'Regisztráció',
            changeData: 'Adatok megváltoztatása',
            invoiceSettings: 'Számlázási adatok',
            shippingSettings: 'Szállítási adatok',
            notificationSettings: 'Értesítések',
            changeEmail: 'E-mail cím megváltoztatása',
            emailConfirmation: 'E-mail cím megerősítése',
            changePassword: 'Jelszó megváltoztatása',
            forgotPasswordMenu: 'Elfelejtett jelszó',
            deleteAccount: 'Fiók törlése',
            logoutMenu: 'Kilépés',
            profileSettings: 'Fiók beállítások',
            otherSettings: 'Egyéb',

            accountSettingsMenu: 'Beállítások',
            dashboardMenu: 'Felhasználói fiók',
            userProfileMenu: 'Profil',
            loginMoreMenu: 'Továbbiak',

            imagesAndDocuments: 'Képek és dokumentumok'
        },
        messages: {
            ...messages,

            ['new' + N + 'SuccessMessage']: 'Az új felhasználó mentésre került',
            ['save' + N + 'SuccessMessage']: 'A felhasználó mentésre került',
            ['delete' + N + 'Success']: 'A felhasználó törölve lett',
            ['delete' + Ns + 'Success']: 'A felhasználók törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli a felhasználót?',
            ['delete' + Ns + 'Question']: 'Biztosan törli a felhasználókat?',
            ['ban' + N + 'Success']: 'A felhasználó le lett tiltva',
            ['ban' + N + 'Question']: 'Biztos, hogy a felhasználót tiltani akarod?',
            ['approve' + N + 'Success']: 'A felhasználó jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod a felhasználót?',
            ['protection' + N + 'Success']: 'A felhasználó adminnak jelölve',
            ['protection' + N + 'Question']: 'Biztosan adminnak jelölöd a felhasználót?',
            ['removeProtection' + N + 'Success']: 'Az admin jog törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd az admin jogot?',
            ['thereAreNo' + Ns]: 'Nincsenek felhasználók',

            notificationSettingsInfo: 'Miről szeretnél e-mail értesítést kapni?',
            forgotPasswordSuccessMessage: 'Az üzenet el lett küldve az e-mail címedre',
            forgotPasswordInfo: 'Jelszó visszaállító hivatkozás küldése e-mailben, az új jelszó visszaállításához',
            resetPasswordSuccessMessage: 'A jelszó frissítve lett',
            changePasswordSuccessMessage: 'A jelszó frissítve lett',
            changeEmailSuccessMessage: 'Az e-mail mentésre került',
            changeDataSuccessMessage: 'Az adatai mentésre kerültek',
            invoiceSettingsSuccessMessage: 'Az adatai mentésre kerültek',
            shippingSettingsSuccessMessage: 'Az adatai mentésre kerültek',
            notificationSettingsSuccessMessage: 'Az adatai mentésre kerültek',
            emailConfirmationSuccessMessage: 'Az e-mail cím megerősítésre került',
            emailConfirmationSendAgainSuccessMessage: 'Az e-mail megerősítő kulcs újra el lett küldve',
            emailConfirmationSendAgainInfo: 'Megerősítő e-mail küldése az e-mail cím érvényesítéséhez',
            deleteAccountSuccessMessage: 'A fiók törölve lett',
            deleteAccountQuestion: 'Biztosan törlöd a fiókot?',
            deleteAccountSuccess: 'A fiók törölve lett',
            deleteAccountInfo: 'A fiók visszaállítható 30 napig, ha a felhasználó belép a rendszerbe',
            thisIsNotYourResetPasswordKey: 'Ez a jelszó visszaállító kulcs nem ehhez a fiókhoz tartozik, lépjen ki a használatához',
            thisIsNotYourEmailConfirmationKey: 'Ez az e-mail megerősítő kulcs nem ehhez a fiókhoz tartozik, lépjen ki a használatához',

            avatarWelcomeText: 'Hello,'
        },
        routes: {
            ...routes,
            ['user' + Ns + 'Route']: '/' + ns,
            [n + 'Route']: '/' + n,
            [ns + 'Route']: '/' + ns
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Felhasználó',
            [ns + 'Title']: 'Felhasználók',
            ['new' + N + 'Title']: 'Új felhasználó',
            ['edit' + N + 'Title']: 'Felhasználó szerkesztése',
            ['user' + Ns + 'Title']: 'Felhasználók',
            ['userDeleted' + Ns + 'Title']: 'Törölt felhasználók',
            ['userBanned' + Ns + 'Title']: 'Véglegesen törölt felhasználók',
            ['my' + Ns + 'Title']: 'Felhasználóim',
            ['myDeleted' + Ns + 'Title']: 'Törölt felhasználóim',
            ['myBanned' + Ns + 'Title']: 'Véglegesen törölt felhasználóim',

            ['dialogDelete' + N + 'Title']: 'Felhasználó törlése',
            ['dialogBan' + N + 'Title']: 'Felhasználó tiltása',
            ['dialogApprove' + N + 'Title']: 'Felhasználó jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Felhasználó adminnak jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Felhasználó admin jogának megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Felhasználók törlése',

            ['statusBanned' + N + 'Title']: 'Tiltva',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Admin',
            ['statusCreated' + N + 'Title']: 'Regisztrált',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt felhasználók',
            ['deleted' + Ns + 'Title']: 'Törölt felhasználók',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Adminok',
            ['lowAuthorStatus' + Ns + 'Title']: '',

            ['select' + Ns]: 'Felhasználók kiválasztása',
            ['select' + N]: 'Felhasználó kiválasztása',

            ['get' + N + 'Description']: (name) => name + ' oldala',

            loginTitle: 'Belépés a profilodba',
            accountTitle: 'Beállítások',
            changeDataTitle: 'Adatok megváltoztatása',
            invoiceSettingsTitle: 'Számlázási adatok',
            shippingSettingsTitle: 'Szállítási adatok',
            notificationSettingsTitle: 'Értesítések',
            changeEmailTitle: 'E-mail cím megváltoztatása',
            emailConfirmationTitle: 'E-mail cím megerősítése',
            signupTitle: 'Regisztráció',
            logoutTitle: 'Kilépés',
            forgotPasswordTitle: 'Elfelejtett jelszó',
            resetPasswordTitle: 'Jelszó visszaállítása',
            changePasswordTitle: 'Jelszó megváltoztatása',
            deleteAccountTitle: 'Fiók törlése',
            profileSettingsTitle: 'Fiók beállítások',
            otherSettingsTitle: 'Egyéb',

            dialogDeleteAccountTitle: 'Fiók törlése',
            userProfileTitle: 'Profil',
            dashboardTitle: 'Felhasználói fiók',
            acceptTermsTitle: 'ÁSZF elfogadása',
            acceptPrivacyTitle: 'Adatvédelem elfogadása',
            socialLoginTitle: 'Belépés közösségi fiókoddal'
        }
    }
}
