import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'product'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Mentés',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Termékek törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['moreFromMy' + Ns + 'Title']: 'További termékeim',
            ['moreFromUser' + Ns + 'Title']: 'További termékek',

            [n + 'SearchTextPlaceholder']: 'Könyv címe, szerző neve, kiadó',
            [n + 'SearchSubmitLabel']: 'Keresés',
            [n + 'RefreshSubmitLabel']: 'Találatok frissítése',

            [n + 'PageInfoPerPage']: ({ startItem, endItem, allItem, perPage, currentPage }) => {
                if (currentPage >= 2 && (endItem - startItem <= perPage)) {
                    return `${currentPage}. oldal - ${allItem} termék összesen`
                }
                if (allItem <= perPage || currentPage < 2) {
                    return `${allItem} termék összesen`
                }
                return `${startItem} - ${endItem}, ${allItem} termék összesen`
            },

            [ns + 'SortRelevance']: 'Relevancia',

            [n + 'OverviewProducts']: 'Termékek',
            [n + 'OverviewImportMenu']: 'Termékek importálása',
            [n + 'OverviewExport']: 'Termékek exportálása',
            [n + 'OverviewUpdateFromWholesale']: 'Frissítése a disztribútor adatbázisából'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Termékek',
            ['my' + Ns + 'Menu']: 'Termékeim',
            ['new' + N + 'Menu']: 'Új termék',
            [ns + 'Menu']: 'Termékek',
            ['deleted' + Ns + 'Menu']: 'Törölt termékek',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt termékek',
            ['protection' + Ns + 'Menu']: 'Védett termékek',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése',
            ['get' + N + 'CategoryAll']: ({ category }) => {
                return 'Összes ' + category.title
            },
            [n + 'KonyvekCategoryAll']: 'Összes könyv'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'Az új termék mentésre került',
            ['save' + N + 'SuccessMessage']: 'A termék mentésre került',
            ['delete' + N + 'Success']: 'A termék törölve lett',
            ['delete' + Ns + 'Success']: 'A termékek törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli az termékt?',
            ['delete' + Ns + 'Question']: 'Biztosan törli az termékekat?',
            ['ban' + N + 'Success']: 'A termék véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy az termékt véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A termék jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod az termékt',
            ['protection' + N + 'Success']: 'A termék védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd az termékt?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek termékek',
            [n + 'ImportNumAffected']: (numAffected) => numAffected + ' termék mentésre került'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Termék',
            [ns + 'Title']: 'Termékek',
            ['new' + N + 'Title']: 'Új termék',
            ['edit' + N + 'Title']: 'Termék szerkesztése',
            ['user' + Ns + 'Title']: 'Termékek',
            ['userDeleted' + Ns + 'Title']: 'Törölt termékek',
            ['userBanned' + Ns + 'Title']: 'Véglegesen törölt termékek',
            ['my' + Ns + 'Title']: 'Termékeim',
            ['myDeleted' + Ns + 'Title']: 'Törölt termékeim',
            ['myBanned' + Ns + 'Title']: 'Véglegesen törölt termékeim',

            ['dialogDelete' + N + 'Title']: 'Termék törlése',
            ['dialogBan' + N + 'Title']: 'Termék törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Termék jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Termék védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Termék védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Termékek törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt termékek',
            ['deleted' + Ns + 'Title']: 'Törölt termékek',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett termékek',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Termékek kiválasztása',
            ['select' + N]: 'Termék kiválasztása'
        }
    }
}
