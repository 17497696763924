import React from 'react'

import TrashIcon from 'konyvesjatek-ui/dist/common/src/svg/TrashIcon'
import DoneIcon from 'konyvesjatek-ui/dist/common/src/svg/DoneIcon'
import EditIcon from 'konyvesjatek-ui/dist/common/src/svg/EditIcon'
import StarIcon from 'konyvesjatek-ui/dist/common/src/svg/StarIcon'
import BlockIcon from 'konyvesjatek-ui/dist/common/src/svg/BlockIcon'
import StarBorderIcon from 'konyvesjatek-ui/dist/common/src/svg/StarBorderIcon'

import FeaturedForm from './Forms/FeaturedForm'
import RemoveFeaturedForm from './Forms/RemoveFeaturedForm'
import capitalize from '../../utils/capitalize'

export function getMenuProps({ appContext, dialog, utils, name, post, requestProps = {}, onSubmit }) {

    const { titles, labels, messages } = appContext

    const N = capitalize(name)

    return {
        name,
        post,
        appContext,
        onDelete: function() {
            //menuActions?.actions.close()
            dialog.actions.open({
                dialogTitle: titles['dialogDelete' + N + 'Title'],
                dialogContent: messages['delete' + N + 'Question'],
                cancelText: labels['cancel' + N + 'Text'],
                submitText: labels['delete' + N + 'Text'],
                onSubmit: async function(e) {
                    const response = await utils.sendRequest({
                        requestName: name + 'Delete',
                        args: { _id: post?._id },
                        redirect: null,
                        timeOut: 1000,
                        ...requestProps.onDeleteSuccess ? requestProps.onDeleteSuccess : {}
                    })

                    if (onSubmit) {
                        await onSubmit(e, response, name + 'Delete')
                    }

                    return response
                },
                successMessage: messages['delete' + N + 'Success']
            })
        },
        onBan: function() {
            //menuActions?.actions.close()
            dialog.actions.open({
                dialogTitle: titles['dialogBan' + N + 'Title'],
                dialogContent: messages['ban' + N + 'Question'],
                cancelText: labels['cancel' + N + 'Text'],
                submitText: labels['ban' + N + 'Text'],
                onSubmit: async function(e) {

                    const response = await utils.sendRequest({
                        requestName: name + 'Ban',
                        args: { _id: post?._id },
                        redirect: null,
                        timeOut: 1000,
                        ...requestProps.onBanSuccess ? requestProps.onBanSuccess : {}

                    })

                    if (onSubmit) {
                        await onSubmit(e, response, name + 'Ban')
                    }

                    return response

                },
                successMessage: messages['ban' + N + 'Success']
            })
        },
        onApprove: function() {
            //menuActions?.actions.close()
            dialog.actions.open({
                dialogTitle: titles['dialogApprove' + N + 'Title'],
                dialogContent: messages['approve' + N + 'Question'],
                cancelText: labels['cancel' + N + 'Text'],
                submitText: labels['approve' + N + 'Text'],
                onSubmit: async function(e) {

                    const response = await utils.sendRequest({
                        requestName: name + 'Approve',
                        args: { _id: post?._id },
                        redirect: null,
                        timeOut: 1000,
                        ...requestProps.onApproveSuccess ? requestProps.onApproveSuccess : {}
                    })

                    if (onSubmit) {
                        await onSubmit(e, response, name + 'Approve')
                    }

                    return response

                },
                successMessage: messages['approve' + N + 'Success']
            })
        },
        onFeatured: function() {
            //menuActions?.actions.close()
            dialog.actions.open({
                dialogTitle: titles['dialogProtection' + N + 'Title'],
                dialogContent: messages['protection' + N + 'Question'],
                Form: (props) => <FeaturedForm {...props} name={name} post={post} />,
                cancelText: labels['cancel' + N + 'Text'],
                submitText: labels['protection' + N + 'Text'],
                onSubmit: async function(e, formData) {

                    const response = await utils.sendRequest({
                        requestName: name + 'Featured',
                        args: formData,
                        redirect: null,
                        timeOut: 1000,
                        ...requestProps.onFeaturedSuccess ? requestProps.onFeaturedSuccess : {}
                    })

                    if (onSubmit) {
                        await onSubmit(e, response, name + 'Featured')
                    }

                    return response

                },
                successMessage: messages['protection' + N + 'Success']
            })
        },
        onRemoveFeatured: function() {
            //menuActions?.actions.close()
            dialog.actions.open({
                dialogTitle: titles['dialogRemoveProtection' + N + 'Title'],
                dialogContent: messages['removeProtection' + N + 'Question'],
                Form: (props) => <RemoveFeaturedForm {...props} name={name} post={post} />,
                cancelText: labels['cancel' + N + 'Text'],
                submitText: labels['removeProtection' + N + 'Text'],
                onSubmit: async function(e, formData) {

                    const response = await utils.sendRequest({
                        requestName: name + 'RemoveFeatured',
                        args: formData,
                        redirect: null,
                        timeOut: 1000,
                        ...requestProps.onRemoveFeaturedSuccess ? requestProps.onRemoveFeaturedSuccess : {}
                    })

                    if (onSubmit) {
                        await onSubmit(e, response, name + 'RemoveFeatured')
                    }

                    return response

                },
                successMessage: messages['removeProtection' + N + 'Success']
            })
        }
    }
}

function getMenu(props = {}) {

    const { appContext, name } = props
    const { menus } = appContext

    const N = capitalize(name)

    return [
        {
            label: menus['edit' + N + 'Menu'],
            href: function(p) {
                return (props.getEditHref) ? props.getEditHref(p) : (p.post?._id) ? '/' + p.post._id + '/edit' : '/'
            },
            role: function(p) {
                const isAdmin = p.user && p.user._status_isFeatured
                const isAuthor = ((p.user?._id && p.user._id === p.post?._author) || (p.user?._id && p.user._id === p.post?._author?._id))
                const isBanned = p.post && p.post._status_isBanned
                const isFeatured = p.post && p.post._status_isFeatured

                if (p.user?._id === p.post?._id && !isAdmin) {
                    return false
                }

                if ((isBanned && !isAdmin) || isFeatured) {
                    return false
                }

                if (isAdmin || isAuthor) {
                    return !!(p.post?._id && p.page !== 'edit' && (p.page === 'content' || !p.page))
                }
                return false
            },
            startIcon: <EditIcon />,
            onlyIcon: true,
            featured: true,
            order: 202
        },
        {
            label: menus['delete' + N + 'Menu'],
            onClick: function(e) {
                e.preventDefault()
                e.stopPropagation()
                if (props.onDelete) {
                    props.onDelete()
                }
            },
            role: function(p) {
                const isAdmin = p.user && p.user._status_isFeatured
                const isAuthor = ((p.user?._id && p.user._id === p.post?._author) || (p.user?._id && p.user._id === p.post?._author?._id))
                const isBanned = p.post && p.post._status_isBanned
                const isFeatured = p.post && p.post._status_isFeatured

                if (p.user?._id === p.post?._id && !isAdmin) {
                    return false
                }

                if ((isBanned && !isAdmin) || isFeatured) {
                    return false
                }

                if (isAdmin || isAuthor) {
                    const isNotDeleted = p.post && p.post._status_isNotDeleted
                    return !!(p.post?._id && isNotDeleted /*&& (p.page === 'content' || p.page === 'edit'|| !p.page)*/ && p.isList)
                }

                return false
            },
            startIcon: <TrashIcon />,
            onlyIcon: true,
            featured: true,
            order: 204
        },
        {
            label: menus['ban' + N + 'Menu'],
            onClick: function(e) {
                e.preventDefault()
                e.stopPropagation()
                if (props.onBan) {
                    props.onBan()
                }
            },
            role: function(p) {
                const isAdmin = p.user && p.user._status_isFeatured
                const isBanned = p.post && p.post._status_isBanned
                const isFeatured = p.post && p.post._status_isFeatured

                if (isBanned || isFeatured) {
                    return false
                }

                if (isAdmin) {
                    return !!(p.post?._id /*&& (p.page === 'content' || p.page === 'edit'|| !p.page)*/ && p.isList)
                }

                return false
            },
            startIcon: <BlockIcon />,
            order: 206
        },
        {
            label: menus['approve' + N + 'Menu'],
            onClick: function(e) {
                e.preventDefault()
                e.stopPropagation()
                if (props.onApprove) {
                    props.onApprove()
                }
            },
            role: function(p) {
                const isAdmin = p.user && p.user._status_isFeatured
                const isValidated = p.post && p.post._status_isValidated
                const isApproved = p.post && p.post._status_isApproved
                const isFeatured = p.post && p.post._status_isFeatured

                if (!isValidated || isApproved || isFeatured) {
                    return false
                }

                if (isAdmin) {
                    return !!(p.post?._id /*&& (p.page === 'content' || p.page === 'edit'|| !p.page)*/ && p.isList)
                }

                return false
            },
            startIcon: <DoneIcon />,
            order: 208
        },
        {
            label: menus['protection' + N + 'Menu'],
            onClick: function(e) {
                e.preventDefault()
                e.stopPropagation()
                if (props.onFeatured) {
                    props.onFeatured()
                }
            },
            role: function(p) {
                const isAdmin = p.user && p.user._status_isFeatured
                const isApproved = p.post && p.post._status_isApproved
                const isFeatured = p.post && p.post._status_isFeatured

                if (!isApproved || isFeatured) {
                    return false
                }

                if (isAdmin) {
                    return !!(p.post?._id /*&& (p.page === 'content' || p.page === 'edit'|| !p.page)*/ && p.isList)
                }

                return false
            },
            startIcon: <StarIcon />,
            order: 210
        },
        {
            label: menus['removeProtection' + N + 'Menu'],
            onClick: function(e) {
                e.preventDefault()
                e.stopPropagation()
                if (props.onRemoveFeatured) {
                    props.onRemoveFeatured()
                }
            },
            role: function(p) {
                const isAdmin = p.user && p.user._status_isFeatured
                const isFeatured = p.post && p.post._status_isFeatured

                if (!isFeatured) {
                    return false
                }

                if (isAdmin) {
                    return !!(p.post?._id /*&& (p.page === 'content' || p.page === 'edit'|| !p.page)*/ && p.isList)
                }

                return false
            },
            startIcon: <StarBorderIcon />,
            order: 212
        },
        {
            label: menus['edit' + N + 'Menu'],
            href: function(p) {
                return (p.post?._id) ? '/' + p.post._id + '/editor' : '/'
            },
            role: function(p) {
                const isEditor = p.user && p.user._isEditor
                const isUserPage = ((p.post?._id && p.post?._author === p.post?._id) || p.post?._id && p.post?._author?._id === p.post?._id)
                const isAuthor = ((p.user?._id && p.user._id === p.post?._author) || (p.user?._id && p.user._id === p.post?._author?._id))

                const notDeleted = p.post && p.post._status_isNotDeleted
                const isFeatured = p.post && p.post._status_isFeatured

                if (!notDeleted || isFeatured) {
                    return false
                }

                return !!(isEditor && isUserPage && !isAuthor)

            },
            startIcon: <EditIcon />,
            onlyIcon: true,
            featured: true,
            order: 202
        }
    ]
}

export default getMenu
