import React, { useContext } from 'react'

import clsx from 'clsx'

import { WappContext } from 'wapplr-react/dist/common/Wapp'

import Button from 'konyvesjatek-ui/dist/common/src/components/Button'
import DoneIcon from 'konyvesjatek-ui/dist/common/src/svg/DoneIcon'
import CloseIcon from 'konyvesjatek-ui/dist/common/src/svg/CloseIcon'
import MenuContext from 'konyvesjatek-ui/dist/common/src/components/Menu/context'
import Label from 'konyvesjatek-ui/dist/common/src/components/Label'
import Menu from 'konyvesjatek-ui/dist/common/src/components/Menu'
import VerticalMoreIcon from 'konyvesjatek-ui/dist/common/src/svg/VerticalMoreIcon'
import PhoneIcon from 'konyvesjatek-ui/dist/common/src/svg/PhoneIcon'
import WorldIcon from 'konyvesjatek-ui/dist/common/src/svg/WorldIcon'
import WalletIcon from 'konyvesjatek-ui/dist/common/src/svg/WalletIcon'
import InstagramIcon from 'konyvesjatek-ui/dist/common/src/svg/InstagramIcon'
import FacebookIcon from 'konyvesjatek-ui/dist/common/src/svg/FacebookIcon'
import MailIcon from 'konyvesjatek-ui/dist/common/src/svg/MailIcon'
import ClockIcon from 'konyvesjatek-ui/dist/common/src/svg/ClockIcon'
import LocationIcon from 'konyvesjatek-ui/dist/common/src/svg/LocationIcon'
import LabelIcon from 'konyvesjatek-ui/dist/common/src/svg/LabelIcon'
import MoneyIcon from 'konyvesjatek-ui/dist/common/src/svg/MoneyIcon'
import CalendarIcon from 'konyvesjatek-ui/dist/common/src/svg/CalendarIcon'
import StatusIcon from 'konyvesjatek-ui/dist/common/src/svg/StatusIcon'
import DownloadIcon from 'konyvesjatek-ui/dist/common/src/svg/DownloadIcon'
import StarIcon from 'konyvesjatek-ui/dist/common/src/svg/StarIcon'
import VisibleIcon from 'konyvesjatek-ui/dist/common/src/svg/VisibleIcon'
import DocumentsIcon from 'konyvesjatek-ui/dist/common/src/svg/DocumentsIcon'

import getStatus from '../../utils/getStatus'
import capitalize from '../../utils/capitalize'

import AppContext from '../App/context'
import IconButton from '../IconButton'

export const defaultComponents = {
    Button: {
        props: {
            variant: 'inline',
            color: undefined,
            value: '',
            target: null,
            startIcon: null,
            endIcon: null,
            icon: null,
            ComponentProps: {},
            icons: undefined
        },
        Component: (props) => {

            const {
                value,
                name,
                post,
                style,
                user,
                statusManager,
                onClick,
                icons,
                startIcon,
                endIcon,
                icon,
                ComponentProps = {},
                className,
                ...rest
            } = props

            const StartIcon = startIcon && icons[startIcon] || null
            const EndIcon = endIcon && icons[endIcon] || null
            const Icon = icon && icons[icon] || null

            if (!value) {
                return null
            }

            return (
                <Button
                    stopPropagation={true}
                    {...rest}
                    Component={value ? 'a' : 'div'}
                    label={ComponentProps.label || 'Open'}
                    href={value}
                    {...{
                        ...StartIcon ? { startIcon: <StartIcon /> } : {},
                        ...EndIcon ? { endIcon: <EndIcon /> } : {},
                        ...Icon ? { icon: <StartIcon /> } : {}
                    }}
                    {...ComponentProps}
                    className={clsx(style.button, className, ComponentProps.className)}
                />
            )
        }
    },
    TextField: {
        props: {
            value: '',
            multiline: false,
            breakAll: false,
            maxRows: null,
            endFix: ''
        },
        Component: (props = {}) => {

            const { value = '', multiline = false, breakAll = false, maxRows = null, endFix, style } = props

            return (
                <div
                    className={clsx(
                        style.textField,
                        {
                            [style.multiline]: multiline,
                            [style.breakAll]: breakAll,
                            [style.maxRows]: maxRows
                        }
                    )}
                    style={(!isNaN(Number(maxRows)) && Number(maxRows) > 2) ? { WebkitLineClamp: maxRows } : {}}
                >
                    {((endFix && value) || (endFix && value === 0)) ? <><span>{value}</span><span
                        className={style.textFieldEndFix}>{endFix}</span></> : value}
                </div>
            )
        }
    },
    Status: {
        props: {
            value: '',
            user: null,
            post: null,
            name: 'post'
        },
        Component: (props) => {
            const context = useContext(WappContext)
            const { wapp } = context
            const appContext = useContext(AppContext)
            const { value, name, style } = props
            const user = props.user || appContext.user
            const statusManager = props.statusManager || wapp.getTargetObject().postTypes.findPostType({ name: name }).statusManager
            const post = props.post?._status === value ? props.post : {
                ...(props.post) ? props.post : {},
                _status: value
            }
            const statusText = post._status && getStatus({ user, post, appContext, statusManager, name })

            if (statusText) {
                return (
                    <div className={style.status}>
                        {statusText}
                    </div>
                )
            }

            return null
        }
    },
    Date: {
        props: {
            value: '',
            name: 'post'
        },
        Component: (props) => {

            const appContext = useContext(AppContext)
            const { value, name = 'post', style } = props
            const N = capitalize(name)

            const dateText = value && appContext.labels['date' + N + 'Format']({ dateText: value })

            if (dateText) {
                return (
                    <div className={style.date}>
                        {dateText}
                    </div>
                )
            }

            return null
        }
    },
    Checkbox: {
        props: {
            value: false
        },
        Component: (props) => {

            const { value, style } = props

            return (
                <div className={style.checkbox}>
                    {(value) ? <DoneIcon /> : <CloseIcon />}
                </div>
            )

        }
    },
    Menu: {
        props: {
            value: [],
            icons: undefined,
            ComponentProps: {},
            type: ''
        },
        Component: React.forwardRef(({ value = [], icons = {}, type, ComponentProps = {} }, ref) => {

            const menuContext = useContext(MenuContext)

            const menu = Array.isArray(value) ? value.map(({ icon, startIcon, endIcon, ...menuItemProps }) => {

                const StartIcon = startIcon && icons[startIcon] || null
                const EndIcon = endIcon && icons[endIcon] || null
                const Icon = icon && icons[icon] || null

                return {
                    ...menuItemProps,
                    ...StartIcon ? { startIcon: <StartIcon /> } : {},
                    ...EndIcon ? { endIcon: <EndIcon /> } : {},
                    ...Icon ? { icon: <StartIcon /> } : {},
                    ...menuItemProps.featured ? {
                        featuredMenuItemProps: {
                            Component: type === 'smallLabels' ? Label : Button,
                            ...type === 'iconCircles' ?
                                {
                                    style: {
                                        padding: '0',
                                        '--digby-icon-font-size': 'calc((var(--digby-paper-padding-y)) * 2 + 1.5em)'
                                    }
                                }
                                :
                                {},
                            ...type === 'smallLabels' ?
                                {
                                    style: {
                                        padding: '2px 8px'
                                    }
                                }
                                :
                                {},
                            ...menuItemProps.featuredMenuItemProps ? menuItemProps.featuredMenuItemProps : {}
                        }
                    } : {}
                }
            }) : []

            return (
                <MenuContext.Provider value={{ ...menuContext, parentRoute: null }}>
                    <Menu
                        ref={ref}
                        {...ComponentProps}
                        menu={menu}
                        PopoverProps={{
                            AnchorComponent: IconButton,
                            ...ComponentProps.PopoverProps ? ComponentProps.PopoverProps : {},
                            AnchorComponentProps: {
                                children: <VerticalMoreIcon />,
                                size: 'small',
                                ...ComponentProps.PopoverProps?.AnchorComponentProps ? ComponentProps.PopoverProps.AnchorComponentProps : {}
                            }
                        }}
                        MenuItemProps={{
                            size: 'small',
                            ...ComponentProps.MenuItemProps ? ComponentProps.MenuItemProps : {}
                        }}
                    />
                </MenuContext.Provider>
            )
        })
    }
}

export const defaultIcons = {

    phone: PhoneIcon,
    world: WorldIcon,
    wallet: WalletIcon,
    instagram: InstagramIcon,
    facebook: FacebookIcon,
    mail: MailIcon,
    clock: ClockIcon,
    location: LocationIcon,
    label: LabelIcon,
    money: MoneyIcon,
    date: CalendarIcon,
    status: StatusIcon,
    download: DownloadIcon,
    star: StarIcon,
    visible: VisibleIcon,
    documents: DocumentsIcon,
    trueOrFalse: function({ post, user, tableData, condition, ...rest }) {
        return (
            <>
                {post[condition] ? <DoneIcon {...rest} /> : <CloseIcon {...rest} />}
            </>
        )
    }
}
