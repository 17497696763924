import App from './components/App'
import titles from './config/constants/titles'
import routes from './config/constants/routes'
import { runPostTypesConfigSync } from './postTypes'
import { getTitle as getAccountTitle } from './components/Account/utils'

export default function setContents(p = {}) {

    const { wapp } = p

    function getTitle({ wapp, res, title = '' }) {
        const config = wapp.getTargetObject().config
        const { siteName = 'Wapplr' } = config
        const { statusCode, statusMessage, errorMessage } = res.wappResponse
        if (statusCode === 404) {
            title = titles.notFoundTitle || statusMessage || 'Not Found'
        }
        if (statusCode === 500) {
            title = errorMessage || statusMessage || 'Internal Server Error'
        }
        return title + ' | ' + siteName
    }

    /*contents for home and some static page*/

    const home = {
        contentName: 'home',
        action: async function action(p) {
            const { wapp, req, res, ...rest } = p
            const r = (rest.status === 404) ? { ...rest } : await wapp.router.routeManager.resolve({
                path: '/page/landing-1',
                req,
                res
            })
            r.status = rest.status
            return r
        }
    }

    wapp.contents.add({
        'home': {
            render: App,
            renderType: 'react',
            title: function(p) {
                return getTitle({ ...p, title: titles.homeTitle })
            }
        }
    })

    wapp.router.replace([
        { path: '/', ...home }
    ])

    wapp.router.replace([
        { path: '/offline', ...home }
    ])

    /*contents for post types, post, document, user...*/

    runPostTypesConfigSync({ action: 'setContents', p: { wapp, routes, titles, getTitle } })

    /*contents for user account*/

    wapp.contents.add({
        account: {
            render: App,
            renderType: 'react',
            title: function(p) {

                const wappResponse = p.res.wappResponse
                const wappRequest = p.req.wappRequest
                const route = wappResponse.route
                const { params } = route

                const title = getAccountTitle({ user: wappRequest.user, page: params.page, titles })

                return getTitle({ ...p, title })
            }
        }
    })

    wapp.router.add([
        { path: routes.accountRoute, contentName: 'account' },
        { path: routes.accountRoute + '/:page', contentName: 'account' },
        { path: routes.accountRoute + '/*', contentName: 'account' },
        { path: '/:slug/*', contentName: 'page' }
    ])

}

export async function contentsMiddleware(req) {

    const wapp = req.wapp

    if (!wapp.contents.contentManager.firstRequest) {
        wapp.contents.contentManager.firstRequest = async function({ req, res }) {

            const categories = res.wappResponse.store.getState('res.responses.categories')

            if (!categories) {

                const userStatusManager = wapp.getTargetObject().postTypes.findPostType({ name: 'user' }).statusManager
                const statusManager = wapp.getTargetObject().postTypes.findPostType({ name: 'category' }).statusManager

                const response = await wapp.requests.send({
                    requestName: 'categoryFindMany',
                    args: {
                        filter: {
                            showOnMenu: true,
                            _operators: {
                                _status: { gt: statusManager.getMinStatus() - 1 },
                                _author_status: {
                                    gt: userStatusManager.getMinStatus() - 1
                                }
                            }
                        },
                        sort: 'ORDER_ASC'
                    },
                    req,
                    res
                })

                if (response && response['categoryFindMany']) {

                    res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                        type: 'INS_RES',
                        name: 'responses',
                        value: { 'categories': response['categoryFindMany'], 'categoryFindMany': null }
                    }))

                }

            }

            const ages = res.wappResponse.store.getState('res.responses.ages')

            if (!ages) {

                const userStatusManager = wapp.getTargetObject().postTypes.findPostType({ name: 'user' }).statusManager
                const statusManager = wapp.getTargetObject().postTypes.findPostType({ name: 'age' }).statusManager

                const response = await wapp.requests.send({
                    requestName: 'ageFindMany',
                    args: {
                        filter: {
                            showOnMenu: true,
                            _operators: {
                                _status: { gt: statusManager.getMinStatus() - 1 },
                                _author_status: {
                                    gt: userStatusManager.getMinStatus() - 1
                                }
                            }
                        },
                        sort: 'ORDER_ASC'
                    },
                    req,
                    res
                })

                if (response && response['ageFindMany']) {

                    res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                        type: 'INS_RES',
                        name: 'responses',
                        value: { 'ages': response['ageFindMany'], 'ageFindMany': null }
                    }))

                }

            }

            const methods = res.wappResponse.store.getState('res.responses.methods')

            if (!methods) {

                const userStatusManager = wapp.getTargetObject().postTypes.findPostType({ name: 'user' }).statusManager
                const statusManager = wapp.getTargetObject().postTypes.findPostType({ name: 'method' }).statusManager

                const response = await wapp.requests.send({
                    requestName: 'methodFindMany',
                    args: {
                        filter: {
                            _operators: {
                                _status: { gt: statusManager.getMinStatus() - 1 },
                                _author_status: {
                                    gt: userStatusManager.getMinStatus() - 1
                                }
                            }
                        }
                    },
                    req,
                    res
                })

                if (response && response['methodFindMany']) {

                    res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                        type: 'INS_RES',
                        name: 'responses',
                        value: { 'methods': response['methodFindMany'], 'methodFindMany': null }
                    }))

                }

            }

        }
    }

}
