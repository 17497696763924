import React, { useContext, useEffect, useState } from 'react'

import getUtils from 'wapplr-react/dist/common/Wapp/getUtils'
import { copyObject } from 'wapplr/dist/common/utils'
import { WappContext } from 'wapplr-react/dist/common/Wapp'

import Typography from 'konyvesjatek-ui/dist/common/src/components/Typography'
import Label from 'konyvesjatek-ui/dist/common/src/components/Label'
import StarIcon from 'konyvesjatek-ui/dist/common/src/svg/StarIcon'
import Paper from 'konyvesjatek-ui/dist/common/src/components/Paper'
import { Divider } from 'konyvesjatek-ui/dist/common/src/components/MenuItem'
import DoneIcon from 'konyvesjatek-ui/dist/common/src/svg/DoneIcon'

import AppContext from '../../../components/App/context'
import AddToCart from '../../order/Cart/AddToCart'

export function AddToCartWithStorage(props) {

    const context = useContext(WappContext)
    const appContext = useContext(AppContext)

    const { storage } = appContext

    const utils = getUtils(context)
    const user = utils.getRequestUser()
    const orderPostTypeName = 'order'
    const storageName = user?._id ? orderPostTypeName + '_' + user._id : orderPostTypeName

    const { post, onChange, simpleButton } = props;

    const [time, update] = useState(Date.now())

    useEffect(() => {
        if (props.effect) {
            props.effect({
                actions: {
                    update: async (q)=>{
                        post.q = q;
                        await update(Date.now())
                    }
                }
            })
        }
        return () => {
            if (props.effect) {
                props.effect({
                    actions: {
                        update: () => null
                    }
                })
            }
        }
    })

    return (
        <AddToCart
            key={time + '-' + post._id + '-q-' + (post.q || 0).toString()}
            post={post}
            onChange={async ({ post }) => {
                const storageData = storage()[storageName] || {}
                if (post?._id) {
                    const cart = (storageData['record.cart']) ? JSON.parse(storageData['record.cart']) : []
                    const cartItem = cart.find((cartItem) => cartItem?._id === post._id)
                    if (cartItem) {
                        if (cartItem.q !== post.q) {
                            cartItem.q = post.q
                            const filteredCart = cart.filter((cartItem) => cartItem.q > 0)
                            storage({
                                [storageName]: {
                                    ...storageData,
                                    ['record.cart']: JSON.stringify(filteredCart)
                                }
                            })
                            await appContext.template.current?.actions?.updateMiniCart()
                            if (onChange) {
                                await onChange({post})
                            }
                        }
                    } else {

                        const enableKeys = [
                            'price',
                            'q',
                            'vatRate',
                            'subtitle',
                            '_id',
                            'thumb',
                            'title',
                            'piecesInThePackage',
                            'disableProduct',
                            'available'
                        ]

                        cart.push({
                            ...enableKeys.reduce((cartItem, key) => {
                                cartItem[key] = post[key]
                                return cartItem
                            }, {})
                        })

                        const filteredCart = cart.filter((cartItem) => cartItem.q > 0)
                        storage({
                            [storageName]: {
                                ...storageData,
                                ['record.cart']: JSON.stringify(filteredCart)
                            }
                        })
                        await appContext.template.current?.actions?.updateMiniCart()
                        if (onChange) {
                            await onChange({post})
                        }
                    }
                }
            }}
            simpleButton={simpleButton}
        />
    )
}

function updatePostsQ({context, appContext, posts}) {

    const utils = getUtils(context)
    const user = utils.getRequestUser()
    const orderPostTypeName = 'order'
    const storageName = user?._id ? orderPostTypeName + '_' + user._id : orderPostTypeName

    const { storage } = appContext

    function getStorageData() {
        const localStorageData = storage()[storageName] || {}
        const memoData = storage(undefined, true)[storageName] || {}
        return { ...localStorageData, ...memoData }
    }

    const storageData = getStorageData()
    const cart = (storageData['record.cart']) ? JSON.parse(storageData['record.cart']) : []
    const newPosts = copyObject(posts)
    cart.forEach((cartItem) => {
        const post = (cartItem?._id && cartItem?.q) ? newPosts.find((p) => p._id === cartItem._id) : null
        if (post) {
            post.q = cartItem.q
        }
    })
    newPosts.forEach((post) => {
        if (post?.q) {
            const cartItem = cart.find((cartItem) => cartItem?._id === post._id)
            if (!cartItem?.q) {
                post.q = 0
            }
        }
    })
    return newPosts;

}

export function transformPosts({ posts, classNames, appContext, context, disableAddToCart, addToCartChange, addToCartEffect }) {

    const newPosts = updatePostsQ({context, appContext, posts});

    return newPosts.map((post) => {

        const { discountText, infoText, featuredChildren, ...rest } = post

        const r = {
            _original: () => {
                return {
                    discountText,
                    infoText,
                    featuredChildren,
                    ...rest
                }
            },
            ...rest,
            contentExtract: <>
                {featuredChildren?.length ?
                    <div className={classNames.productsFeatured}>
                        {featuredChildren.map((menu, i) => <Label
                            key={i}
                            label={menu.label}
                            color={menu.color}
                            startIcon={<StarIcon />}
                        />)
                        }
                    </div> : null
                }
                {discountText ?
                    <Paper
                        color={'featured'}
                        variant={'text'}
                        size={'none'}
                        elevation={0}
                    >
                        <Typography variant={'h6'}
                                    weight={600}>{discountText}</Typography>
                    </Paper> : null
                }
                {infoText ?
                    <>
                        <Divider className={classNames.productsItemDivider} />
                        <Label
                            size={'label1'}
                            TypographyProps={{ weight: 500 }}
                            startIcon={
                                <div>
                                    <Paper
                                        color={'primary'}
                                        borderRadius={'50%'}
                                        size={'none'}
                                        variant={'contained'}
                                        className={classNames.customDoneIcon}
                                    >
                                        <DoneIcon />
                                    </Paper>
                                </div>
                            }
                        >
                            {infoText}
                        </Label>
                    </>
                    : null
                }
            </>,
            MenuItemProps: {
                enableDivider: false,
                ContentComponentProps: {
                    ImageProps: {
                        className: classNames.cardImage
                    },
                    ContentComponentProps: {
                        style: {
                            position: 'unset'
                        }
                    }
                }
            }
        }

        if (!disableAddToCart) {
            r.MenuItemProps.ContentComponentProps.footerContent = <AddToCartWithStorage
                post={r}
                onChange={addToCartChange}
                {...addToCartEffect ? {
                    effect: ({actions})=>addToCartEffect({actions, post: r})
                } : {}}
            />
        }

        return r
    })
}
